import axios, { AxiosResponse } from "axios";
import {setupInterceptorsTo} from './Interceptors';

import { Textbaustein, Variable, Vorlage, VorlageTextbaustein } from "@/models/VorlageModels";
import { Input, TextAbschnitt, Vertrag, GenerateWordDocumentRequest } from "@/models/VertragModels";
import { LoginRequest, RefreshLoginRequest, LogoutRequest } from "@/models/AuthModels";
import { Dokument, CreateDokumentRequest } from "@/models/DokumentModels";
import { Vertragspartner } from "@/models/VertragspartnerModels";


/* DEV */
// import config from '../../appsettings.dev.json'


/* PROD */
const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 

const BASE_URL = config.vertragapi.apiUrl;
const API_KEY = config.vertragapi.apiKey;

const apiClient = setupInterceptorsTo(axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ApiKey': API_KEY
  }
}));

export default {

  //#region Auth
  login(data: LoginRequest): Promise<AxiosResponse> {
    return apiClient.post("/login", data)
  },

  validateOTPLogin(data: LoginRequest): Promise<AxiosResponse> {
    return apiClient.post("/login/otp", data)
  },

  refreshLogin(data: RefreshLoginRequest) {
    return apiClient.post("/login/refresh", data)
  },

  logout(data: LogoutRequest): Promise<AxiosResponse> {
    return apiClient.post('/logout', data);
  },

  //#endregion

  //#region Dokuemnte
  getAllDokumente(): Promise<AxiosResponse> {
    return apiClient.get("/dokumente")
  },

  getDokumentById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/dokument/${id}`)
  },

  downloadDokumentById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/dokument/${id}/download`, { responseType: 'blob' })
  },

  createDokument(data: CreateDokumentRequest) {
    return apiClient.post("/dokument", data, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important: Use multipart/form-data content type
      },
    })
  },

  updateDokument(data: CreateDokumentRequest) {
    return apiClient.put("/dokument", data, {
      headers: {
        'Content-Type': 'multipart/form-data', // Important: Use multipart/form-data content type
      },
    })
  },

  deleteDokument(id: number) {
    return apiClient.delete(`/dokument/${id}`)
  },
  //#endregion

  //#region Input
  getAllInputs(): Promise<AxiosResponse> {
    return apiClient.get("/inputs")
  },

  getInputById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/input/${id}`)
  },

  createInput(data: Input) {
    return apiClient.post("/input", data)
  },

  updateInput(data: Input) {
    return apiClient.put("/input", data)
  },

  deleteInput(id: number) {
    return apiClient.delete(`/input/${id}`)
  },
  //#endregion

  //#region Textabschnitt
  getAllTextabschnitte(): Promise<AxiosResponse> {
    return apiClient.get("/textabschnitte")
  },

  getTextabschnittById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/textabschnitt/${id}`)
  },

  createTextabschnitt(data: TextAbschnitt) {
    return apiClient.post("/textabschnitt", data)
  },

  updateTextabschnitt(data: TextAbschnitt) {
    return apiClient.put("/textabschnitt", data)
  },

  deleteTextabschnitt(id: number) {
    return apiClient.delete(`/textabschnitt/${id}`)
  },
  //#endregion

  //#region Textbaustein
  getAllTextbausteine(): Promise<AxiosResponse> {
    return apiClient.get("/textbausteine")
  },

  getTextbausteinById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/textbaustein/${id}`)
  },

  createTextbaustein(data: Textbaustein) {
    return apiClient.post("/textbaustein", data)
  },

  updateTextbaustein(data: Textbaustein) {
    return apiClient.put("/textbaustein", data)
  },

  deleteTextbaustein(id: number) {
    return apiClient.delete(`/textbaustein/${id}`)
  },
  //#endregion

  //#region Variable
  getAllVariablen(): Promise<AxiosResponse> {
    return apiClient.get("/variablen")
  },

  getVariableById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/variable/${id}`)
  },

  createVariable(data: Variable) {
    return apiClient.post("/variable", data)
  },

  updateVariable(data: Variable) {
    return apiClient.put("/variable", data)
  },

  deleteVariable(id: number) {
    return apiClient.delete(`/variable/${id}`)
  },
  //#endregion
  
  //#region Vertrag
  getAllVertraege(): Promise<AxiosResponse> {
    return apiClient.get("/vertraege")
  },

  getVertragByPersonaldaten(guid: string): Promise<AxiosResponse> {
    return apiClient.get(`/vertrag/personaldaten/${guid}`)
  },

  getVertragById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/vertrag/${id}`)
  },

  createVertrag(data: Vertrag) {
    return apiClient.post("/vertrag", data)
  },

  updateVertrag(data: Vertrag) {
    return apiClient.put("/vertrag", data)
  },

  deleteVertrag(id: number) {
    return apiClient.delete(`/vertrag/${id}`)
  },

  generateWordDocument(data: GenerateWordDocumentRequest) {
    return apiClient.post("/vertrag/document/generate/word", data)
  },

  getWordDocument(fileName: string): Promise<AxiosResponse> {
    return apiClient.get(`/vertrag/document/word?fileName=${fileName}`, {responseType: 'blob' }); // Important to specify responseType as blob
  },
  //#endregion
  
  //#region Vertragspartner
  getAllVertragspartner(): Promise<AxiosResponse> {
    return apiClient.get("/vertragspartner")
  },

  getVertragspartnerById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/vertragspartner/${id}`)
  },

  createVertragspartner(data: Vertragspartner) {
    return apiClient.post("/vertragspartner", data)
  },

  updateVertragspartner(data: Vertragspartner) {
    return apiClient.put("/vertragspartner", data)
  },

  deleteVertragspartner(id: number) {
    return apiClient.delete(`/vertragspartner/${id}`)
  },
  //#endregion

  //#region Vorlage
  getAllVorlagen(): Promise<AxiosResponse> {
    return apiClient.get("/vorlagen")
  },

  getVorlageById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/vorlage/${id}`)
  },

  createVorlage(data: Vorlage) {
    return apiClient.post("/vorlage", data)
  },

  updateVorlage(data: Vorlage) {
    return apiClient.put("/vorlage", data)
  },

  deleteVorlage(id: number) {
    return apiClient.delete(`/vorlage/${id}`)
  },
  //#endregion
  
  //#region VorlageTextbaustein
  getAllVorlageTextbausteine(): Promise<AxiosResponse> {
    return apiClient.get("/vorlagetextbausteine")
  },

  getVorlageTextbausteinById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/vorlagetextbaustein/${id}`)
  },

  createVorlageTextbaustein(data: VorlageTextbaustein) {
    return apiClient.post("/vorlagetextbaustein", data)
  },

  updateVorlageTextbaustein(data: VorlageTextbaustein) {
    return apiClient.put("/vorlagetextbaustein", data)
  },

  deleteVorlageTextbaustein(id: number) {
    return apiClient.delete(`/vorlagetextbaustein/${id}`)
  },
  //#endregion

  //#region Database
  isDatabaseUpToDate(): Promise<AxiosResponse> {
    return apiClient.get("/database/status")
  },

  updateDatabase(): Promise<AxiosResponse> {
    return apiClient.get("/database/update")
  },
  //#endregion
}